import store from '@/store'
import i18n from '@/locales'

export function getPageTitle(pageTitle, type) {
  const platformName = store.getters.getPlatformConfig && store.getters.getPlatformConfig.portalName ? store.getters.getPlatformConfig.portalName : ''
  if (pageTitle && !type) {
    return `${i18n.global.t(pageTitle)}-${platformName}`
  } else if (pageTitle && type === 1) {
    return `${pageTitle}-${platformName}`
  }
  return `${platformName}`
}

export function formattedDate(timestamp) {
  // 将时间戳转换为日期对象
  const date = new Date(+timestamp)
  const year = date.getFullYear()
  const month = date.getMonth() + 1 // 月份是从0开始的，所以要加1
  const day = date.getDate()
  const hours = date.getHours()
  const minutes = date.getMinutes()
  // const seconds = date.getSeconds()

  // 构造格式化后的时间字符串
  return `${year}-${month}-${day} ${hours}:${minutes}`
}
