import axios from 'axios';
import qs from 'qs';
import setting from './setting';
import store from '../store/index';
import { showToast } from 'vant';
const ua = navigator.userAgent;
const ipad = ua.match(/(iPad).*OS\s([\d_]+)/);
const isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/);
const isAndroid = ua.match(/(Android)\s+([\d.]+)/);
const isMobile = isIphone || isAndroid;
const request = axios.create({
  baseURL: setting.baseURL,
  timeout: 10000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
  }
});
request.interceptors.request.use(config => {
  if (config.method === 'post' || config.method === 'put' || config.method === 'delete') {
    // 序列化
    if (config.headers['Content-Type'] === 'application/x-www-form-urlencoded;charset=utf-8') {
      config.data = qs.stringify(config.data);
    }
  } else {
    config.params = {
      version: store.getters.getVersion,
      ...config.params
    };
  }
  return config;
}, error => {
  // do something with request error
  if (!isMobile) {
    ElMessage.error({
      showClose: false,
      message: error || '发起请求出错'
    });
  } else {
    showToast({
      type: 'fail',
      message: error || '发起请求出错',
      duration: 2000
    });
  }
  return Promise.reject(error);
});

// response interceptor
request.interceptors.response.use(async response => {
  const {
    code,
    message
  } = response.data;
  if (code !== 200) {
    // switch (code) {
    //
    // }
    if (!isMobile) {
      ElMessage({
        message: message,
        type: 'error',
        duration: 2000
      });
    } else {
      showToast({
        type: 'fail',
        message,
        duration: 2000
      });
    }
    return Promise.reject(message);
  } else {
    return response.data;
  }
}, error => {
  if (error.message.includes('timeout')) {
    return Promise.reject(new Error(error));
  } else {
    if (!isMobile) {
      ElMessage({
        message: '网络异常！请重试',
        type: 'error',
        duration: 5 * 1000
      });
    } else {
      showToast({
        type: 'fail',
        message: '网络异常！请重试',
        duration: 5 * 1000
      });
    }
    return Promise.reject(error);
  }
  // return Promise.reject(error)
});
export default request;