import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import BaiduMap from 'vue-baidu-map-3x'
import setting from './config/setting'

import i18n from './locales'
import './rem'

import request from './config/api'
const app = createApp(App)
app.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: setting.baiduKey
})
app.use(i18n)
app.use(store)
app.use(router)
app.provide('$axios', request)
app.mount('#app')
