export default {
  common: {
    // 公用
    h5Header: {
      title: '御安华荣',
      lang: '中文'
    },
    h5Menu: {
      home: '首页',
      expert: '专家',
      expertDetail: '专家详情',
      service: '服务',
      dynamic: '动态',
      dynamicDetail: '文章详情',
      aboutUs: '关于我们',
      joinUs: '加入我们',
      contactUs: '联系我们'
    },
    h5: {
      checkMore: '查看更多',
      expertNo: '更多专家资料正在整理中，敬请期待...'
    }
  },
  h5Index: {
    service: '服务领域',
    serviceDetail: '查看详情',
    expert: '专家团队',
    aboutUs: '关于我们',
    information: '新闻资讯',
    contactUs: '联系我们',
    contactMessage: '留言咨询',
    contactMessageName: '姓名',
    errMessageName: '请输入姓名',
    contactMessagePhone: '电话号码',
    errMessagePhone: '请输入正确的电话号码',
    contactMessageEmail: '邮箱',
    errMessageEmail1: '请输入邮箱',
    errMessageEmail: '请输入正确的邮箱格式',
    contactMessageContent: '请输入咨询内容',
    errMessageContent: '请输入留言内容',
    addContactUs: '提交咨询',
    contactUsTips:
      '* 请认真填写以上信息，我们承诺不向本公司以外人士透露上述信息。'
  },
  h5Service: {
    bannerText: 'OUR SERVICES',
    bannerText1: '服务领域',
    breadcrumb: '首页',
    breadcrumb1: '专家',
    breadcrumb2: '专家详情'
  },
  h5Expert: {
    bannerText: 'PROFESSIONALS',
    bannerText1: '专家团队',
    expertDescribe: '御安华荣以资深跨国行业专家为班底，汇聚国内外律所/咨询机构的一流人才。核心团队合作多年，拥有丰富的实战经验和海外人脉资源，能够为客户提供高效、专业的服务。同时，公司还与国内外多家知名学府和研究机构建立了紧密的合作关系，不断提升团队的专业素养和创新能力。'
  },
  h5ExpertDetail: {
    bannerText: 'PROFESSIONALS',
    bannerText1: '专家团队'
  },
  h5Information: {
    bannerText: 'NEWSROOM',
    bannerText1: '新闻资讯',
    breadcrumb: '首页',
    breadcrumb1: '动态',
    breadcrumb2: '文章详情'
  },
  h5InformationDetail: {
    bannerText: 'NEWSROOM',
    bannerText1: '新闻资讯',
    source: '来源：',
    summary: '摘要：',
    author: '作者：'
  },
  h5AboutUs: {
    bannerText: 'ABOUT US',
    bannerText1: '关于我们'
  },
  h5JoinUs: {
    bannerText: 'JOIN US',
    bannerText1: '加入我们',
    jobEmail: '招聘邮箱：',
    jobAddress: '办公地址：',
    jobPhone: '招聘电话：'
  },
  h5ContactUs: {
    bannerText: 'CONTACT US',
    bannerText1: '联系我们',
    phone: '电话：',
    fax: '传真：',
    email: '邮箱：',
    wechat: '微信号：',
    weibo: '微博：',
    linkedIn: 'LinkedIn：',
    telegeram: 'Telegeram：',
    facebook: 'Facebook：',
    twitter: 'Twitter：',
    address: '地址：'
  }
}
