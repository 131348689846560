import { createRouter, createWebHistory } from 'vue-router'
import { getPageTitle } from '@/lib/js/utils.js'

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/h5/h5Index.vue'),
    meta: {
      title: 'common.h5Menu.home'
    }
  },
  {
    path: '/h5Expert',
    name: 'h5Expert',
    component: () => import('../views/h5/h5Expert.vue'),
    meta: {
      title: 'common.h5Menu.expert'
    }
  },
  {
    path: '/h5ExpertDetail',
    name: 'h5ExpertDetail',
    component: () => import('../views/h5/h5ExpertDetail.vue'),
    meta: {
      title: 'common.h5Menu.expertDetail'
    }
  },
  {
    path: '/h5Service',
    name: 'h5Service',
    component: () => import('../views/h5/h5Service.vue'),
    meta: {
      title: 'common.h5Menu.service'
    }
  },
  {
    path: '/h5Information',
    name: 'h5Information',
    component: () => import('../views/h5/h5Information.vue'),
    meta: {
      title: 'common.h5Menu.dynamic'
    }
  },
  {
    path: '/h5InformationDetail',
    name: 'h5InformationDetail',
    component: () => import('../views/h5/h5InformationDetail.vue'),
    meta: {
      title: 'common.h5Menu.dynamicDetail'
    }
  },
  {
    path: '/h5AboutUs',
    name: 'h5AboutUs',
    component: () => import('../views/h5/h5AboutUs.vue'),
    meta: {
      title: 'common.h5Menu.aboutUs'
    }
  },
  {
    path: '/h5JoinUs',
    name: 'h5JoinUs',
    component: () => import('../views/h5/h5JoinUs.vue'),
    meta: {
      title: 'common.h5Menu.joinUs'
    }
  },
  {
    path: '/h5ContactUs',
    name: 'h5ContactUs',
    component: () => import('../views/h5/h5ContactUs'),
    meta: {
      title: 'common.h5Menu.contactUs'
    }
  },
  {
    path: '/webIndex',
    name: 'webIndex',
    component: () => import('../views/web/webIndex.vue'),
    meta: {
      title: 'common.h5Menu.home'
    }
  },
  {
    path: '/webExpert',
    name: 'webExpert',
    component: () => import('../views/web/webExpert.vue'),
    meta: {
      title: 'common.h5Menu.expert'
    }
  },
  {
    path: '/webExpertDetail',
    name: 'webExpertDetail',
    component: () => import('../views/web/webExpertDetail.vue'),
    meta: {
      title: 'common.h5Menu.expertDetail'
    }
  },
  {
    path: '/webService',
    name: 'webService',
    component: () => import('../views/web/webService.vue'),
    meta: {
      title: 'common.h5Menu.service'
    }
  },
  {
    path: '/webContactUs',
    name: 'webContactUs',
    component: () => import('../views/web/webContactUs'),
    meta: {
      title: 'common.h5Menu.contactUs'
    }
  },
  {
    path: '/webJoinUs',
    name: 'webJoinUs',
    component: () => import('../views/web/webJoinUs'),
    meta: {
      title: 'common.h5Menu.joinUs'
    }
  },
  {
    path: '/webAboutUs',
    name: 'webAboutUs',
    component: () => import('../views/web/webAboutUs'),
    meta: {
      title: 'common.h5Menu.aboutUs'
    }
  },
  {
    path: '/webInformation',
    name: 'webInformation',
    component: () => import('../views/web/webInformation'),
    meta: {
      title: 'common.h5Menu.dynamic'
    }
  },
  {
    path: '/webInformationDetail',
    name: 'webInformationDetail',
    component: () => import('../views/web/webInformationDetail'),
    meta: {
      title: 'common.h5Menu.dynamicDetail'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // 滚动到页面顶部
  window.scrollTo(0, 0)
  document.title = getPageTitle(to.meta.title)
  next()
})

export default router
