import { createStore } from 'vuex'
import request from '../config/api'

export default createStore({
  state: {
    version: localStorage.getItem('HN_VERSION') || 1,
    platformConfig: null
  },
  getters: {
    getVersion: state => {
      return state.version
    },
    getPlatformConfig: state => {
      return state.platformConfig || {}
    }
  },
  mutations: {
    setVersion: (state, value) => {
      localStorage.setItem('HN_VERSION', value)
      state.version = value
    },
    setPlatformConfig: (state, value) => {
      state.platformConfig = value
    }
  },
  actions: {
    getPlatformConfig({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.platformConfig && state.platformConfig.id === state.version.toString()) {
          resolve(state.platformConfig)
        } else {
          request
            .get('/app/index/getPlatformConfig')
            .then(res => {
              res.data.jobImage = res.data.jobImage ? JSON.parse(res.data.jobImage) : []
              commit('setPlatformConfig', res.data)
              resolve(res.data)
            })
            .catch(error => {
              reject(error)
            })
        }
      })
    }
  },
  modules: {}
})
