export default {
  common: {
    // 公用
    h5Header: {
      title: 'Hainan Yuan Huarong',
      lang: 'EN'
    },
    h5Menu: {
      home: 'Home',
      expert: 'Professionals',
      expertDetail: '专家详情',
      service: 'Services',
      dynamic: 'Newsroom',
      dynamicDetail: '文章详情',
      aboutUs: 'About Us',
      joinUs: 'Join Us',
      contactUs: 'Contact Us'
    },
    h5: {
      checkMore: 'View More',
      expertNo: 'More expert information is being collated, please stay tuned...'
    }
  },
  h5Index: {
    service: 'Our Services',
    serviceDetail: 'View Details',
    expert: 'Professional Team',
    aboutUs: 'About Us',
    information: 'Newsroom',
    contactUs: 'Contact us',
    contactMessage: 'leave a message to consult',
    contactMessageName: 'Name',
    errMessageName: 'Name cannot be empty, please enter!',
    contactMessagePhone: 'Phone',
    errMessagePhone: 'Please enter a valid phone number',
    contactMessageEmail: 'E-mail',
    errMessageEmail1: 'Email cannot be empty, please enter!',
    errMessageEmail: 'Please enter a valid email address.',
    contactMessageContent: 'Message',
    errMessageContent: 'Message cannot be empty, please enter!',
    addContactUs: 'SUBMIT',
    contactUsTips:
      '* Please fill in the above information carefully. We promise not to disclose the above information to anyone outside the company.'
  },
  h5Service: {
    bannerText: 'Our Services',
    bannerText1: 'OUR SERVICES',
    breadcrumb: 'Home',
    breadcrumb1: 'Professionals',
    breadcrumb2: 'Detail'
  },
  h5Expert: {
    bannerText: 'Professionals',
    bannerText1: 'PROFESSIONALS',
    major: '专业领域：',
    expertDescribe: 'Yu\'an Huarong has a team of senior multinational industry experts, bringing together top-notch talents from domestic and foreign law firms/consulting firms. The core team has been cooperating for many years and has rich practical experience and overseas network resources, which can provide efficient and professional services to customers.'
  },
  h5ExpertDetail: {
    bannerText: 'Professionals',
    bannerText1: 'PROFESSIONALS'
  },
  h5Information: {
    bannerText: 'Newsroom',
    bannerText1: 'NEWSROOM',
    breadcrumb: 'Home',
    breadcrumb1: 'Newsroom',
    breadcrumb2: 'Detail'
  },
  h5InformationDetail: {
    bannerText: 'Newsroom',
    bannerText1: 'NEWSROOM',
    source: 'Source：',
    summary: 'Summary：',
    author: 'Author：'
  },
  h5AboutUs: {
    bannerText: 'About Us',
    bannerText1: 'ABOUT US'
  },
  h5JoinUs: {
    bannerText: 'Join Us',
    bannerText1: 'JOIN US',
    jobEmail: 'Email：',
    jobAddress: 'Add：',
    jobPhone: 'Tel：'
  },
  h5ContactUs: {
    bannerText: 'Contact Us',
    bannerText1: 'CONTACT US',
    phone: 'Tel：',
    fax: 'Fax：',
    email: 'Email：',
    wechat: 'Wechat：',
    weibo: 'Weibo：',
    linkedIn: 'LinkedIn：',
    telegeram: 'Telegeram：',
    facebook: 'Facebook：',
    twitter: 'Twitter：',
    address: 'Add：'
  }
}
