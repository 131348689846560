import { createI18n } from 'vue-i18n'
import zhCN from './zh'
import enUS from './en'

const lang = localStorage.getItem('HN_VERSION') ? localStorage.getItem('HN_VERSION') === '1' ? 'zh-cn' : 'en-us' : 'zh-cn'

const i18n = createI18n({
  legacy: false,
  locale: lang, // 默认显示语言
  messages: {
    'zh-cn': zhCN,
    'en-us': enUS
  }
})

export default i18n
